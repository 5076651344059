import React from 'react';
import Leiding from '../components/Leiding';
import TakInfo from '../components/TakInfo';
import Takheader from '../components/Takheader';
export default function Tak() {
  return (
    <div>
    <div><Takheader /></div>
    <div className="textelement">
       
      <TakInfo />
      <Leiding />
    </div>
    </div>
  );
}
