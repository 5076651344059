import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import './sliderWidget/assets/css/styles.css';

const KNOOP = gql`
  query GetKnoop($id: ID!) {
    tak(id: $id) {
      data {
        id
        attributes {
          naam
          leidings {
            data {
              id
              attributes {
                naam
                totem
                bijnaam
                ervaring
                foto {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function DeLeiding() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(KNOOP, {
    variables: { id: id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  // Ensure leiding is defined and is an array
  const leidings = data.tak?.data?.attributes?.leidings?.data;

  if (!Array.isArray(leidings)) {
    return <p>No Leiding available</p>;
  }

  return (
    <div className="LeidingGrid overflow">
      {leidings.map((leiding) => (
        <div key={leiding.id} className="LeidingCard overflow">
          <div className="card_img_wrapper overflow">
            <img
              className="card_img overflow"
              src={`https://api.scouts121.be${leiding.attributes.foto.data.attributes.url}`}
              alt={leiding.attributes.naam}
            />
            
          </div>
          <p className="LeidingTitle">{leiding.attributes.naam}</p>
            <div className="LeidingInfo">
              <h2>{leiding.attributes.naam}</h2>
              <p>Bijnaam: {leiding.attributes.bijnaam}</p>
              <p>Totem: {leiding.attributes.totem}</p>
              <p>{leiding.attributes.ervaring}
              {leiding.attributes.ervaring === 1 ? 'ste' : 'de'} jaar leiding</p>
            </div>
        </div>
      ))}
    </div>
  );
}

