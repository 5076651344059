import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
const GET_TAK = gql`
  query GetTak($id: ID!) {
    tak(id: $id) {
      data {
        id
        attributes {
          naam
          description
        }
      }
    }
  }
`;

export default function DeLeiding() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_TAK, {
    variables: { id: id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const tak = data.tak.data;

  return (
    <div>
      <Helmet>
        <title>
          {' '}
          {data.tak.data.attributes.naam} | Scouts 121 Oude-God Mortsel
        </title>
        <meta
          name="description"
          content={data.tak.data.attributes.description}
        />
        <meta
          name="keywords"
          content="contact, contacteren, e-mail, scouts121, 121, scouts"
        />
        <meta name="HandheldFriendly" content="true" />
      </Helmet>
      <h1>{tak.attributes.naam}</h1>
      <div className="takinfo">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          children={tak.attributes.description}
        />
      </div>
    </div>
  );
}
